'use client';
import { ArrowUpIcon } from '@heroicons/react/20/solid';
import { FloatButton } from 'antd';

function ScrollToTopButton() {
  return (
    <FloatButton.BackTop
      shape="circle"
      style={{ insetBlockEnd: 100 }}
      visibilityHeight={100}
      icon={<ArrowUpIcon className="h-5 text-primary" />}
    />
  );
}

export default ScrollToTopButton;
